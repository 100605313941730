import React from 'react'
import { Link, navigate } from "gatsby"
import { getCurrentUser, isLoggedIn, logout } from "../utils/auth"



import * as styles from './navigation.module.css'

const Navigation = () => (
  <nav role="navigation" className={styles.container} aria-label="Main">
    <Link to="/" className={styles.logoLink}>
      <span className={styles.logo} />
      {/* <span className={styles.navigationItem}>Gatsby Starter Contentful</span> */}
      <span className={styles.navigationItem}>Dynatron Capital</span>
    </Link>
    <ul className={styles.navigation}>
      <li className={styles.navigationItem}>
        <Link to="/" activeClassName="active">
          Home
        </Link>
      </li>
      <li className={styles.navigationItem}>
        <Link to="/blog/" activeClassName="active">
          Blog
        </Link>
      </li>
      {/* <li className={styles.navigationItem}>
        <Link to="/about/" activeClassName="active">
          About
        </Link>
      </li> */}
      {/* <li className={styles.navigationItem}>
        <Link to="/app/login/" activeClassName="active">
          Login
        </Link>
      </li> */}
      {/* <li className={styles.navigationItem}>
      {isLoggedIn() ? (
          <a
            href="/"
            onClick={event => {
              event.preventDefault()
               navigate(`/app/profile`)
            }}
          >
            Profile
          </a>
        ) : null}
      </li>
      <li className={styles.navigationItem}>
      {isLoggedIn() ? (
          <a
            href="/"
            onClick={event => {
              event.preventDefault()
              logout(() => navigate(`/app/login`))
            }}
          >
            Logout
          </a>
        ) : (
          <a
            href="/"
            onClick={event => {
              event.preventDefault()
                navigate(`/app/home`)
            }}
          >
            Login
          </a>
        )}
      </li> */}
    </ul>
  </nav>
)

export default Navigation
